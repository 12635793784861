import * as yup from "yup";
import { PhoneNumberUtil } from "google-libphonenumber";
import dayjs from "dayjs";

const weightRegex = RegExp(/^(?!00000)[0-9]+(?:\.[0-9]+)?(?:\s*(kg|lbs))?$/);

const validTLDs = [
  "com",
  "org",
  "net",
  "in",
  "gov",
  "edu",
  "mil",
  "int",
  "info",
  "biz",
  "name",
  "museum",
  "areo",
  "coop",
  "pro",
  "jobs",
  "mobi",
  "tel",
  "travel",
]; // Add more TLDs as needed
const tldRegex = validTLDs.join("|");

const usZipRegex = RegExp(/^(?!00000)[0-9]{5}(?:-[0-9]{4})?$/);
const emailRegex = RegExp(
  new RegExp(`^[\\w.+-]+@[a-zA-Z\\d.-]+\\.(${tldRegex})(?:\\.[a-zA-Z]{2,})?$`)
);

const VINRegex = RegExp(/^[A-Za-z0-9]{17}$/);

function getErrorsFromValidationError(validationError: any) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

function handleErrorMeg(msg: any, schema: any) {
  try {
    schema.validateSync(msg, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// Custom Yup validation method for phone number validation
export const phoneSchema = (isRequired: boolean) =>
  yup.string().test("phone", "Invalid phone number", function (value) {
    if (!value) return true; // Allow empty values
    if (value.length <= 3 && !isRequired) {
      return true;
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  });

export let emailVerificationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
  password: yup.string().required("Password is required"),
});

export function emailValidation(values: any) {
  return handleErrorMeg(values, emailVerificationSchema);
}
export let profileSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  mobile: phoneSchema(true).required("Mobile number is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
});

export function profileValidation(values: any) {
  return handleErrorMeg(values, profileSchema);
}
export let addEnquirySchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  query_type: yup.object().required("Query type is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
  details: yup.string().required("Details is required"),
});

export function candidateEnquiryValidation(values: any) {
  return handleErrorMeg(values, addEnquirySchema);
}
export let employerEnquirySchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  query_type: yup.object().required("Query type is required"),
  organisation_name: yup.string().required("Organisation Name is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  organisation_email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Organisation Email ID is required"),
  details: yup.string().required("Details is required"),
});

export function employerValidation(values: any) {
  return handleErrorMeg(values, employerEnquirySchema);
}
export let paymentSchema = yup.object().shape({
  duration: yup.string().required("Duration is required"),
  date_range: yup.string().required("Date range is required"),
});

export function paymentValidation(values: any) {
  return handleErrorMeg(values, paymentSchema);
}
export let editPlanSchema = yup.object().shape({
  plan_name: yup.string().required("Plan Name is required"),
  paymen_type: yup.string().required("Payment Type is required"),
  price: yup.string().required("Price is required"),
  hires: yup.string().required(" Hires is required"),
  job_slots: yup.string().required("Job Slots is required"),
  featured_job_slots: yup.string().required("Job Branding is required"),
  search_results: yup.string().required("Search Results is required"),
  cv_views: yup.string().required("CV Views is required"),
  posts: yup.string().required("Posts is required"),
  details: yup.string().required(" Description is required"),
});

export function planValidation(values: any) {
  return handleErrorMeg(values, editPlanSchema);
}
export let viewEnquirySchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  query_type: yup.object().required("Query type is required"),
  organisation_name: yup.string().required("Organisation name is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
  organisation_email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
  details: yup.string().required("Details is required"),
});

export function enquiryValidation(values: any) {
  return handleErrorMeg(values, viewEnquirySchema);
}
export let subscriptionEnquirySchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  organisation_name: yup.string().required("Organisation Name is required"),
  mobile_number: phoneSchema(true).required("Mobile Number is required"),
  organisation_email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Organisation Email ID is required"),
  number_of_hires: yup.string().required("Number of Hires / Year is required"),
});

export function subscriptionValidation(values: any) {
  return handleErrorMeg(values, subscriptionEnquirySchema);
}
export let registerSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
  phone: phoneSchema(true).required("Mobile number is required"),
  service: yup.string().required("Service is required"),
  service_status: yup.string().required("Service status is required"),
  rank: yup.string().required("Rank is required"),
  branch: yup.string().required("Branch is required"),
});

export function RegisterValidationSchema(values: any) {
  return handleErrorMeg(values, registerSchema);
}

export let registerEmployerSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  organisation_name: yup.string().required("Organisation name is required"),
  organisation_email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Organisation email ID is required"),
  phone: phoneSchema(true).required("Mobile number is required"),
  organisation_size: yup.string().required("Organisation size is required"),
});

export function RegisterEmployerValidationSchema(values: any) {
  return handleErrorMeg(values, registerEmployerSchema);
}

export let phoneValidationSchema = yup.object().shape({
  phone: phoneSchema(true).required("Mobile number is required"),
});

export function PhoneValidationSchema(values: any) {
  return handleErrorMeg(values, phoneValidationSchema);
}

export let loginSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
  password: yup.string().required("Password is required"),
});

export function LoginValidationSchema(values: any) {
  return handleErrorMeg(values, loginSchema);
}

export let personalInformationSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  phone: phoneSchema(true).required("Mobile number is required"),
  organisation_size: yup.string().required("Organisation Size is required"),
  head_office_location: yup.string().required("Location is required"),
  organisation_address: yup.string().required("Address is required"),
  organisation_email_id: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
});

export function PersonalValidationSchema(values: any) {
  return handleErrorMeg(values, personalInformationSchema);
}

export let socialMediaSchema = yup.object().shape({
  linkedin: yup.string().required("This field  is required"),
  facebook: yup.string().required("This field  is required"),
  instagram: yup.string().required("This field is required"),
});

export function SocialValidationSchema(values: any) {
  return handleErrorMeg(values, socialMediaSchema);
}

export let aboutOrganisationSchema = yup.object().shape({
  introduction: yup.string().required("This field is required"),
});

export function aboutValidationSchema(values: any) {
  return handleErrorMeg(values, aboutOrganisationSchema);
}
export let leadersSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last nameis required"),
  designation: yup.string().required("Designation is required"),
  link: yup.string().required("Link is required"),
  short_intro: yup.string().required("Introduction is required"),
});

export function leaderValidationSchema(values: any) {
  return handleErrorMeg(values, leadersSchema);
}
export let spotilightsSchema = yup.object().shape({
  video_link: yup.string().required("This field is required"),
  heading: yup.string().required("This field is required"),
  description: yup.string().required("This field is required"),
});

export function spotilightsValidationSchema(values: any) {
  return handleErrorMeg(values, spotilightsSchema);
}
export let editDetailsSchema = yup.object().shape({
  organisation_name: yup.string().required("This field is required"),
  gender: yup.string().required("This field is required"),
  service_status: yup.string().required("This field is required"),
});

export function editValidationSchema(values: any) {
  return handleErrorMeg(values, editDetailsSchema);
}
export let otpSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
});

export function OtpSchema(values: any) {
  return handleErrorMeg(values, otpSchema);
}
export let searchSchema = yup.object().shape({
  category: yup.string().nullable(),
  location: yup.string().nullable(),
  keyword: yup.string().nullable(),
});

export function SearchValidationSchema(values: any) {
  return handleErrorMeg(values, searchSchema);
}
export let passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(15, "Password must not exceed 15 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one digit")
    .matches(
      /[!@#$%^&*()_]/,
      "Password must contain at least one special character (!@#$%^&*()_)"
    ),

  confirmPassword: yup
    .string()
    .required("Confirm New Password is required")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export function PasswordSchema(values: any) {
  return handleErrorMeg(values, passwordSchema);
}

export let resetpasswordSchema = yup.object().shape({
  currentPassword: yup.string().required("Current Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long")
    .max(15, "Password must not exceed 15 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one digit")
    .matches(
      /[!@#$%^&*()_]/,
      "Password must contain at least one special character (!@#$%^&*()_)"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm New Password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

export function ResetPasswordvalidation(values: any) {
  return handleErrorMeg(values, resetpasswordSchema);
}

export function Filterchema(values: any) {
  return handleErrorMeg(values, filterSchema);
}
export let filterSchema = yup.object().shape({
  // company: yup.string().nullable().optional(),
  // location: yup.string().nullable().optional(),
  // status: yup.string().nullable().optional(),
});

export function AddCandidateValidation(values: any) {
  return handleErrorMeg(values, addCandidateSchema);
}
export let addCandidateSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  military_id: yup.string().required("Military ID Number is required"),
  email: yup

    .string()
    .matches(emailRegex, "Invalid email ID")
    .required("Email ID is required"),
  mobile_number: phoneSchema(true).required("Mobile Number is required"),
  date_of_birth: yup
    .date()
    .required("Date of Birth is required")
    .test("age", "Must be at least 18 years old", (value) => {
      if (value) {
        const today = dayjs();
        const dob = dayjs(value);
        const age = today.diff(dob, "year");
        return age >= 18;
      }
      return false;
    })
    .typeError("Date of Birth is required"),
  work_experience: yup.number().required("Work Experience is required"),
  joining_type: yup
    .object()
    .required("Joining is required")
    .typeError("Joining is required"),
  gender: yup
    .object()
    .required("Gender is required")
    .typeError("Gender is required"),
  service: yup
    .object()
    .required("Service is required")
    .typeError("Service is required"),
  rank: yup.object().required("Rank is required").typeError("Rank is required"),
  branch: yup
    .object()
    .required("Branch is required")
    .typeError("Branch is required"),
  current_location: yup
    .object()
    .required("Current Location is required")
    .typeError("Current Location is required"),
  willing_to_relocate: yup
    .object()
    .required("Relocate is required")
    .typeError("Relocate is required"),
  disability: yup
    .object()
    .required("Specially Abled is required")
    .typeError("Specially Abled is required"),
  service_status: yup
    .object()
    .required("Service Status is required")
    .typeError("Service Status is required"),
    has_corporate_experience:yup
    .object()
    .required("Corporate Experience is required")
    .typeError("Corporate Experience is required"),
});
export function AddEmployerValidation(values: any) {
  return handleErrorMeg(values, addemployerSchema);
}

export let addemployerSchema = yup.object().shape({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid Organization Email ID")
    .required("Organization Email ID is required"),
  size: yup.string().required("Organization Size is required"),
  organization_name:yup.string().required("Organization Name is required"),
  // address_line_1: yup.string().required("Address Line 1 is required"),
  // address_line_2: yup.string().required("Address Line 2 is required"),
  // pincode: yup.string().required("Pincode is required"),
  // gst_number: yup.string().required("GST Number is required"),

  mobile_number: phoneSchema(true).required("Mobile number is required"),
  location: yup
    .object()
    .required("Current Location is required")
    .typeError("Current Location is required"),
  // industries:yup.array().nullable().required("Industries is required"),
  // benefits:yup.array().required("Benefits and Perks is required"),

  // plan: yup.object().optional()
  // .typeError("Current Location is required"),
  // payment_type: yup.string().required("Payment Type is required"),
  is_featured:yup
  .object()
  .required("Featured is required")
  .typeError("Featured is required"),
  linkedin: yup.string().nullable().optional(),
  facebook: yup.string().nullable().optional(),
  instagram: yup.string().nullable().optional(),
});

export function EditCandidateValidation(values: any) {
  return handleErrorMeg(values, editbulkSchema);
}
export let editbulkSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),

  preferred_location: yup
    .array()
    .required("Current Location is required")
    .typeError("Current Location is required"),
  service: yup
    .object()
    .required("Service is required")
    .typeError("Service is required"),
  rank: yup.object().required("Rank is required").typeError("Rank is required"),
  branch: yup
    .object()
    .required("Branch is required")
    .typeError("Branch is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email id")
    .required("Email id is required"),
});

export function EditMemberValidation(values: any) {
  return handleErrorMeg(values, editbulkMemberSchema);
}
export let editbulkMemberSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  // last_name: yup.string().required("Last name is required"),

  designation: yup
    .object()
    .required("Designation is required")
    .typeError("Designation is required"),
  access_level: yup
    .object()
    .required("Access Level is required")
    .typeError("Access Level is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email id")
    .required("Email id is required"),
  mobile_number: phoneSchema(true).required("Mobile number is required"),
});

export function AddJobValidation(values: any) {
  return handleErrorMeg(values, addjobshcema);
}

export let addjobshcema = yup.object().shape({
  title: yup.string().required("Job Title is required"),
  category: yup
    .object()
    .required("Job Category is required")
    .typeError("Job Category is required"),
  salary_low: yup.string().required("Salary Range from is required"),
  salary_high: yup.string().required("Salary Range to is required"),
  experience_low: yup.string().required("Experience Low is required"),
  experience_high: yup.string().required("Experience High is required"),
  type: yup
    .object()
    .required("Employment Type is required")
    .typeError("Employment Type is required"),
  job_location: yup
    .array()
    .required("Job Locations is required")
    .typeError("Job Locations is required"),
  target_date: yup.string().required("Apply Before is required"),
  employer_id: yup
    .object()
    .required("Employer is required")
    .typeError("Employer is required"),
  is_featured: yup
    .object()
    .required("Featured is required")
    .typeError("Featured is required"),
});

export function EditJobValidation(values: any) {
  return handleErrorMeg(values, editJobbulkSchema);
}
export let editJobbulkSchema = yup.object().shape({
  employer_id: yup
    .object()
    .required("Company Name is required")
    .typeError("Company Name is required"),
  title: yup.string().required("Title is required"),

  //  job_location:yup.array()
  //   .required("Job Location is required")
  //   .typeError("Current Location is required"),
  category: yup
    .object()
    .required("Category is required")
    .typeError("Category is required"),
  type: yup
    .object()
    .required("Job Type is required")
    .typeError("Job Type is required"),
  opening: yup.string().required("Openings is required"),
});

export function AddUserValidation(values: any) {
  return handleErrorMeg(values, addusershcema);
}

export let addusershcema = yup.object().shape({
  name: yup.string().required("Employer Name is required"),
  designation: yup
    .object()
    .required("Designation is required")
    .typeError("Designation is required"),
  mobile: phoneSchema(true).required("Mobile number is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email id")
    .required("Email ID is required"),
  dob: yup
    .date()
    .required("Date of Birth is required")
    .test("age", "Must be at least 18 years old", (value) => {
      if (value) {
        const today = dayjs();
        const dob = dayjs(value);
        const age = today.diff(dob, "year");
        return age >= 18;
      }
      return false;
    })
    .typeError("Date of Birth is required"),
  current_location: yup
    .object()
    .required("Current Location is required")
    .typeError("Current Location is required"),
  joined_date: yup.string().required("Date of Joining is required"),
  access_level: yup
    .object()
    .required("Access Level is required")
    .typeError("Access Level is required"),
  status: yup
    .object()
    .required("Status is required")
    .typeError("Status is required"),
});
